<template>
	<article class="course__block true-false">
		<h2>Question #{{ id }}</h2>
		<p>{{ question }}</p>
		<v-radio-group v-model="selectedAnswer">
			<v-radio
				v-for="option in options"
				:key="option.id"
				:id="String(option.id)"
				:value="option.id"
				:name="String(option.id)"
				:label="option.content"
			></v-radio>
		</v-radio-group>
	</article>
</template>

<script>
export default {
	name: "StudentBlockTrueFalse",
	props: {
		id: {
			type: Number,
			require: true
		},
		question: {
			type: String,
			require: true
		},
		options: {
			type: Array,
			require: true
		}
	},
	computed: {},
	data() {
		return {
			selectedAnswer: null
		};
	}
};
</script>

<style lang="scss" scoped></style>
